<template>
  <div class="inputText">
    <label v-if="props.label" :for="id" class="inputText__label sr-only">
      {{ props.label }}
    </label>
    <input
      ref="inputEl"
      v-model="inputValue"
      :id="id"
      class="inputText__input"
      :type="props.type"
      :placeholder="placeholder"
      autocomplete="off"
      spellcheck="false"
      required
      @keydown.esc="clear"
    />
    <span
      :class="['clearButton', inputValue ? 'clearButton--visible' : undefined]"
    >
      <button
        type="button"
        :class="[
          'clearButton__button',
          inputValue ? 'clearButton__button--visible' : undefined,
        ]"
        aria-label="Tyhjennä tekstikenttä"
        :aria-hidden="inputValue ? undefined : true"
        :tabindex="inputValue ? undefined : -1"
        @click="clear"
      >
        <i-mdi-close class="clearButton__icon" />
      </button>
    </span>
  </div>
</template>

<script setup lang="ts">
import { ref, useTemplateRef, watch } from 'vue'
import { watchDebounced } from '@vueuse/core'
import { useInputField } from '@/composables/inputfield'

const props = withDefaults(
  defineProps<{
    type: string
    label?: string
    value?: string
    disabled?: boolean
    placeholder?: string
  }>(),
  {
    type: 'text',
    label: undefined,
    value: undefined,
    disabled: false,
    placeholder: undefined,
  },
)

const inputValue = ref<string>('')
const inputEl = useTemplateRef('inputEl')
const { id } = useInputField()

const modelValue = defineModel<string>({
  default: '',
})

watch(
  modelValue,
  (value) => {
    inputValue.value = value
  },
  {
    immediate: true,
  },
)

const clear = async () => {
  inputValue.value = ''
  modelValue.value = ''

  if (inputEl.value) {
    await new Promise((resolve) => window.requestAnimationFrame(resolve))
    inputEl.value.focus()
  }
}

watchDebounced(
  inputValue,
  (value) => {
    modelValue.value = value
  },
  { debounce: 300 },
)
</script>

<style lang="scss">
.inputText {
  position: relative;

  &__input {
    width: 100%;
    max-width: 100px;
    padding: 0 2.3em 0 0.75em;
    font-size: var(--font-size-small);
    line-height: 2;
    color: var(--color-header-text);
    background-color: transparent;
    border: solid 1px rgba(255, 255, 255, 0.5);
    border-radius: 10em;
    outline: 0;
    transition:
      border-color var(--transition-normal),
      box-shadow var(--transition-normal);

    @media (min-width: 400px) {
      max-width: 150px;
      font-size: var(--font-size-normal);
    }

    .inputText:hover & {
      border-color: var(--color-primary-light);
    }

    .inputText:focus-within & {
      border-color: var(--color-primary-light);
      background-color: var(--color-primary-glow);
      box-shadow: 0 0 0.5em 0.5em var(--color-primary-glow);
    }
  }
}

.clearButton {
  pointer-events: none;
  position: absolute;
  right: 0.75em;
  top: 50%;
  transform: translateY(-50%);

  &--visible {
    pointer-events: auto;
  }

  &__button {
    display: block;
    width: 1em;
    height: 1em;
    padding: 0;
    border: 0;
    outline: 0;
    color: #000;
    background-color: var(--color-header-text);
    border-radius: 100%;
    opacity: 0;
    transform: scale(0.5);
    transform-origin: center;
    transition:
      transform var(--transition-normal),
      opacity var(--transition-normal),
      background-color var(--transition-normal);

    &:hover,
    &:focus-visible {
      background-color: var(--color-primary-light);
    }

    &:focus-visible {
      box-shadow: 0 0 0.5em 0.5em var(--color-primary-glow);
    }

    &--visible {
      opacity: 1;
      pointer-events: visible;
      transform: scale(1);
    }
  }
}
</style>
