import type { Day, Article } from '@/types'
import { type Ref, ref, computed, shallowRef, triggerRef, watch } from 'vue'
import { config, searchQuery } from '@/lib/config'

export interface ArticleProcessorOptions {
  afterTimestamp?: Ref<number | undefined>
  chunks?: boolean
  autoInsertNextChunk?: boolean
}

export const weekdays = [
  'Sunnuntai',
  'Maanantai',
  'Tiistai',
  'Keskiviikko',
  'Torstai',
  'Perjantai',
  'Lauantai',
]

export const useArticleProcessor = (
  userOptions: ArticleProcessorOptions = {},
) => {
  const options: ArticleProcessorOptions = {
    chunks: false,
    afterTimestamp: ref(undefined),
    autoInsertNextChunk: false,
    ...userOptions,
  }

  const articles = shallowRef<Article[]>([])
  const visibleArticles = ref<Day[]>([])
  const nextChunk = ref<number>(0)

  /**
   * Set articles to be processed
   *
   * @param {Article[]} items - Articles to set
   * @returns {Ref<Article[]>}
   */
  const setArticles = (items: Article[] = []): typeof articles => {
    if (options.afterTimestamp) {
      articles.value = items.filter((item: Article) => {
        // Filter by afterTimestamp
        if (
          options.afterTimestamp &&
          options.afterTimestamp.value &&
          item.timestamp <= options.afterTimestamp.value
        )
          return false

        return true
      })
    } else {
      articles.value = items
    }

    return articles
  }

  /**
   * Clear articles of the processor
   *
   * @returns {Ref<Article[]>}
   */
  const clear = (): typeof articles => {
    articles.value = []

    return articles
  }

  /**
   * Latest timestamp of the articles
   */
  const latestTimestamp = computed<number | undefined>(() => {
    if (!articles.value.length) return undefined

    return articles.value[0].timestamp
  })

  /**
   * Filtered articles
   */
  const filteredArticles = computed<Article[]>(() => {
    if (!articles.value.length) return []

    const searchQueryRegex = new RegExp(
      searchQuery.value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'),
      'i',
    )
    const katsoRegExp = new RegExp('\\bkatso\\b', 'i')
    const questionMarkRegExp = new RegExp('\\?', 'i')

    // Define search targets
    const searchTargets = {
      title: config.value.searchTargets.includes('title'),
      feed: config.value.searchTargets.includes('feed'),
      category: config.value.searchTargets.includes('category'),
    }

    return articles.value.reduce((acc: Article[], item: Article) => {
      // Remove duplicates
      if (
        config.value.showDuplicates === false &&
        acc.some((article) => article.title === item.title)
      )
        return acc

      // Search query
      if (
        searchQuery.value.length &&
        !(searchTargets.title === true && searchQueryRegex.test(item.title)) &&
        !(searchTargets.feed === true && searchQueryRegex.test(item.feed)) &&
        !(
          searchTargets.category === true &&
          searchQueryRegex.test(item.category)
        )
      )
        return acc

      // Hidden feed
      if (config.value.hiddenFeeds.includes(item.feedId)) return acc

      // If "katso" is not allowed word
      if (config.value.hideKatso === true && katsoRegExp.test(item.title))
        return acc

      // If question mark is not allowed
      if (
        config.value.hideQuestionMark === true &&
        questionMarkRegExp.test(item.title)
      )
        return acc

      acc.push(item)
      return acc
    }, [] as Article[])
  })

  /**
   * Chunked articles
   */
  const chunkedArticles = computed<Day[][]>(() => {
    if (options.chunks === false || !filteredArticles.value.length) return []

    const chunkSize = 30

    return Array.from(
      { length: Math.ceil(filteredArticles.value.length / chunkSize) },
      (v, i) => {
        return filteredArticles.value.slice(
          i * chunkSize,
          i * chunkSize + chunkSize,
        )
      },
    ).reduce((acc: Day[][], chunk: Article[]) => {
      acc.push(groupArticlesByDay(chunk))

      return acc
    }, [])
  })

  /**
   * When chunked articles change, insert next chunk if autoInsertNextChunk is true
   */
  watch(chunkedArticles, () => {
    if (options.chunks === false || options.autoInsertNextChunk === false)
      return

    visibleArticles.value = []
    insertNextChunk(true)
  })

  /**
   * Group articles by day
   *
   * @param {Article[]} items - Articles to group
   * @returns {Day[]}
   */
  const groupArticlesByDay = (items: Article[] = []): Day[] => {
    let dayIndex = -1

    return items.reduce((acc: Day[], article: Article) => {
      if (!acc.some((day) => day.id === article.date)) {
        acc.push({
          id: article.date,
          label: `${weekdays[article.day]} ${article.date}`,
          articles: [],
        })

        dayIndex++
      }

      // Push article to day
      acc[dayIndex].articles.push(article)

      return acc
    }, [] as Day[])
  }

  /**
   * Insert next chunk to visible articles
   *
   * @returns {boolean} True if chunk was inserted
   */
  const insertNextChunk = (reset = false): boolean => {
    if (reset === true) nextChunk.value = 0

    const chunk = chunkedArticles.value[nextChunk.value]
    if (!chunk) return false

    for (const chunkDay of chunk) {
      // Look for existing day. If it does exist, push articles to it.
      // Otherwise push the whole day.
      const existingDay = visibleArticles.value.find(
        (day) => day.id === chunkDay.id,
      )

      if (existingDay) {
        existingDay.articles.push(...chunkDay.articles)
        continue
      }

      visibleArticles.value.push(chunkDay)
    }

    nextChunk.value++

    return true
  }

  /**
   * Prepend new articles to the list
   *
   * @param {Article[]} items - Articles to prepend
   */
  const insertNewArticles = (items: Article[]) => {
    if (!items.length) return

    articles.value.unshift(...JSON.parse(JSON.stringify(items)))
    triggerRef(articles)
  }

  return {
    articles,
    setArticles,
    clear,
    latestTimestamp,
    filteredArticles,
    chunkedArticles,
    visibleArticles,
    insertNextChunk,
    nextChunk,
    insertNewArticles,
  }
}
