<template>
  <div
    ref="wrapEl"
    class="articleList"
    tabindex="-1"
    aria-label="Artikkelilistaus"
  >
    <div v-for="day of props.items" :key="day.id" class="articleList__day">
      <h2 class="articleList__title">
        {{ day.label }}
      </h2>
      <div class="articleList__items">
        <article
          v-for="article of day.articles"
          :key="article.id"
          :class="['article', article.category ? 'article--hasCategory' : null]"
        >
          <a
            class="article__link"
            :href="`${env.VITE_API_ROOT}${article.url}`"
            :target="config.openInNewTab === true ? '_blank' : ''"
            rel="nofollow noopener"
          >
            <div class="article__col article__col--left">
              <h3 class="article__title" v-html="article.title" />
              <dl class="article__details">
                <dt class="sr-only">Aika</dt>
                <dd class="article__time">
                  {{ article.time }}
                </dd>
                <dt class="sr-only">Lähde</dt>
                <dd class="article__feed">
                  {{ article.feed }}
                </dd>
                <template v-if="article.category">
                  <dt class="sr-only">Aihe</dt>
                  <dd class="article__category">
                    {{ article.category }}
                  </dd>
                </template>
                <div
                  class="clickCounter"
                  v-if="props.clicks && props.clicks[article.id]"
                >
                  <dt class="clickCounter__label">
                    <i-mdi-eye />
                    <span class="sr-only">Klikkauksia</span>
                  </dt>
                  <dd class="clickCounter__value">
                    {{ props.clicks[article.id] }}
                  </dd>
                </div>
              </dl>
            </div>
            <div
              v-if="config.showImages && article.image?.url"
              class="article__col article__col--right"
            >
              <img
                :src="
                  article.image?.url
                    ? `${env.VITE_API_ROOT}${article.image.url}`
                    : undefined
                "
                :srcset="
                  article.image?.url
                    ? `${env.VITE_API_ROOT}${article.image.url_2x} 2x`
                    : undefined
                "
                class="article__image"
                alt=""
                width="100"
                height="100"
                loading="lazy"
                aria-hidden="true"
              />
            </div>
            <span
              v-if="props.newArticleIds.includes(article.id)"
              class="article__bannerWrap"
            >
              <span class="article__banner" />
            </span>
          </a>
        </article>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useTemplateRef } from 'vue'
import type { Day, ArticleClicks } from '@/types'
import { config } from '@/lib/config'

const props = withDefaults(
  defineProps<{
    items: Day[]
    clicks?: ArticleClicks
    newArticleIds?: number[]
  }>(),
  {
    items: () => [],
    clicks: () => ({}) as ArticleClicks,
    newArticleIds: () => [],
  },
)

const env = import.meta.env
const wrapEl = useTemplateRef('wrapEl')

const focus = () => {
  if (!wrapEl.value) return

  wrapEl.value.focus({
    preventScroll: true,
  })
}

defineExpose({
  focus,
})
</script>

<style lang="scss" scoped>
@import '@/assets/mixins';

.articleList {
  outline: 0;

  &__title {
    @include shadow;
    position: sticky;
    top: var(--header-height);
    z-index: 1;
    margin: 0;
    padding: 0.7em var(--spacing-section);
    color: var(--color-text);
    background: var(--color-subtitle-bg);
    border-top: solid 1px var(--color-subtitle-border);
    border-bottom: solid 1px var(--color-subtitle-border);
    font-size: var(--font-size-normal);
    font-weight: var(--font-weight-bold);
    transition:
      background var(--transition-normal),
      color var(--transition-normal),
      border-color var(--transition-normal);
  }
}

.article {
  display: block;

  &__bannerWrap {
    display: block;
    position: absolute;
    overflow: hidden;
    right: 0;
    top: 0;
    width: 2em;
    height: 2em;
  }

  &__banner {
    position: absolute;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    left: 50%;
    top: 1em;
    color: #fff;
    background: var(--color-primary);
    width: 200%;
    height: 0.75em;
    white-space: nowrap;
    text-transform: uppercase;
    text-align: center;
    font-size: 0.7em;
    line-height: 1;
    transform: translateX(calc(-50% + 1em)) rotate(45deg);
  }

  &__link {
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: var(--spacing-single);
    padding: 0 var(--spacing-section);
    color: var(--color-text);
    text-decoration: none;
    outline: 0;
    border-bottom: solid 1px var(--color-article-border);
    background-color: transparent;
    transition:
      background-color var(--transition-normal),
      color var(--transition-normal),
      border-color var(--transition-normal);

    .article:last-child & {
      border-bottom: 0;
    }

    &:after {
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 5px;
      background: var(--color-primary);
      content: ' ';
      transform: translateX(-100%);
      will-change: transform;
      transition:
        transform var(--transition-normal),
        box-shadow var(--transition-normal);
    }

    html.no-touch & {
      &:hover,
      &:focus {
        background: var(--color-article-bg-hover);

        &:after {
          opacity: 1;
          transform: translateX(0);
        }
      }
    }

    &:visited {
      color: var(--color-text-visited);

      .article__time,
      .clickCounter,
      .clickCounter__label {
        color: var(--color-text-visited);
      }

      &:after {
        background: var(--color-text-visited);
        box-shadow: none !important;
      }
    }
  }

  &__col {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;

    &--left {
      flex: 1 1 auto;
      justify-content: center;
      padding: var(--spacing-single) 0;
    }

    &--right {
      max-width: 100px;
      flex: 0 0 25%;
      padding: var(--spacing-half) 0;
    }
  }

  &__image {
    display: block;
    width: 100%;
    height: auto;
    border-radius: 0.3em;
  }

  &__title {
    font-weight: normal;
    font-size: var(--font-size-normal);
    line-height: 1.6;

    .article__link:focus-visible & {
      text-decoration: underline;
    }
  }

  &__details {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    font-size: var(--font-size-small);
    margin: 0.8em 0 -0.2em 0;
    padding-left: 3em;
  }

  &__time,
  &__feed,
  &__category,
  &__clicks {
    display: inline-block;
    margin: 0.1em 0;
  }

  &__time,
  &__feed,
  &__category {
    &:last-of-type {
      margin-right: 0.75em;
    }
  }

  &__time {
    position: absolute;
    left: 0;
    top: 0;
    font-weight: bold;
    width: 3em;
    color: var(--color-primary);
  }

  &__feed {
    font-weight: bold;

    .article--hasCategory & {
      &:after {
        display: inline;
        content: '\a0/\a0';
        font-weight: normal;
      }
    }
  }

  &__category {
    display: inline;
  }
}

.clickCounter {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  white-space: nowrap;
  align-items: center;
  padding: 0.1em 0.5em;
  line-height: 1;
  border-radius: 10em;
  border: solid 1px var(--color-article-border);
  transition: border-color var(--transition-normal);

  &__label,
  &__value {
    transition: color var(--transition-normal);
  }

  &__label {
    color: var(--color-primary);
  }

  &__value {
    margin-left: 0.4em;
  }
}
</style>
