<template>
  <div class="inputRadio">
    <input
      :id="id"
      type="radio"
      v-model="modelValue"
      :value="value"
      :disabled="props.disabled"
      class="inputRadio__input sr-only"
    />
    <label :for="id" class="inputRadio__label">
      <span class="radio">
        <span class="radio__toggle" />
      </span>
      <span class="inputRadio__text">
        {{ props.label }}
      </span>
    </label>
  </div>
</template>

<script setup lang="ts">
import { useInputField } from '@/composables/inputfield'

const props = withDefaults(
  defineProps<{
    label?: string
    value: string
    disabled?: boolean
  }>(),
  {
    label: undefined,
    disabled: false,
  },
)

const modelValue = defineModel<boolean | string | number>({
  default: false,
})

const { id } = useInputField()
</script>

<style lang="scss" scoped>
.inputRadio {
  position: relative;
  display: block;
  background-color: var(--color-field-bg);
  will-change: background-color;
  transition: background-color var(--transition-normal);
  text-underline-offset: 0.2em;

  &:first-child {
    border-top-left-radius: var(--radius-small);
    border-top-right-radius: var(--radius-small);
  }

  &:last-child {
    border-bottom-left-radius: var(--radius-small);
    border-bottom-right-radius: var(--radius-small);
  }

  html.no-touch & {
    &:hover,
    &:has(.inputRadio__input:focus-visible) {
      background-color: var(--color-field-bg-hover);
    }
  }

  &:has(.inputRadio__input:focus-visible) {
    text-decoration: underline;
  }

  &__label {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    gap: 1em;
    line-height: 1.5;
    padding: 0.8em var(--spacing-single);
    border-radius: var(--radius-small);

    .inputRadio:has(.inputRadio__input:disabled) & {
      cursor: not-allowed;
    }

    .inputRadio:has(.inputRadio__input:not(:disabled)) & {
      cursor: pointer;
    }
  }

  &__text {
    display: block;
    flex: 1 1 auto;
  }
}

.radio {
  display: block;
  flex: 0 0 1em;
  height: 1em;
  border: solid 1px var(--color-header-text);
  border-radius: 10em;
  box-shadow: none;
  transition: box-shadow var(--transition-normal);
  will-change: box-shadow;

  .inputRadio:has(.inputRadio__input:checked) & {
    border-color: var(--color-primary-light);
    background-color: var(--color-primary-glow);
    box-shadow: 0 0 1em 1em var(--color-primary-glow);
  }

  &__toggle {
    display: block;
    width: 100%;
    height: 100%;
    background-color: var(--color-primary-light);
    border-radius: 50%;
    opacity: 0;
    transform: scale(0);
    will-change: opacity, transform;
    transition:
      opacity var(--transition-normal),
      transform var(--transition-normal);

    .inputRadio:has(.inputRadio__input:checked) & {
      opacity: 1;
      transform: scale(0.5);
    }
  }
}
</style>
