<template>
  <Header transparent>
    <HeaderCol fill>
      <h2 class="headerTitle">Asetukset</h2>
    </HeaderCol>
    <HeaderCol>
      <ToolBox>
        <ToolBoxItem label="Sulje asetukset" v-bartender-close>
          <i-mdi-close />
        </ToolBoxItem>
      </ToolBox>
    </HeaderCol>
  </Header>

  <div class="config">
    <Fieldset>
      <template #legend>Väriteema</template>
      <template #icon>
        <i-mdi-brush />
      </template>
      <InputRadio
        v-for="item of themes"
        :key="item.name"
        v-model="config.colorTheme"
        :label="item.label"
        :value="item.name"
      />
      <InputSwitch v-model="config.darkMode" label="Tumma väriteema" />
    </Fieldset>
    <Fieldset>
      <template #legend>Artikkelit</template>
      <template #icon>
        <i-mdi-bullhorn />
      </template>
      <InputSwitch v-model="config.showImages" label="Näytä kuvat" />
      <InputSwitch
        v-model="config.openInNewTab"
        label="Avaa linkit uuteen välilehteen"
      />
      <InputSwitch
        v-model="config.pollArticles"
        label="Lataa uudet artikkelit taustalla"
      />
      <InputSwitch
        v-model="config.showDuplicates"
        label="Näytä kaksoiskappaleet"
      />
      <InputSwitch
        v-model="config.hideKatso"
        :label="`Piilota katso sanan sisältävät artikkelit`"
      />
      <InputSwitch
        v-model="config.hideQuestionMark"
        label="Piilota kysymysmerkin sisältävät artikkelit"
      />
    </Fieldset>
    <Fieldset>
      <template #legend>Haun kohdistaminen</template>
      <template #icon>
        <i-mdi-search />
      </template>
      <InputSwitch
        v-for="item of searchTargets"
        :key="item.name"
        v-model="config.searchTargets"
        :label="item.label"
        :value="item.name"
        :disabled="
          config.searchTargets.length <= 1 &&
          config.searchTargets.includes(item.name)
        "
      />
    </Fieldset>
    <Fieldset>
      <template #legend>Piilotetut lähteet</template>
      <template #icon>
        <i-mdi-eye-off />
      </template>
      <Spinner v-if="feedsApi.loading.value === true" bright />
      <Notification
        v-else-if="feedsApi.error.value"
        :text="feedsApi.error.value"
      >
        <Button @click="loadFeeds" text="Yritä uudelleen" />
      </Notification>
      <template v-else-if="feeds.length">
        <InputSwitch
          v-for="item of feeds"
          :key="item.name"
          v-model="config.hiddenFeeds"
          :label="item.title"
          :value="item.name"
          :disabled="
            config.hiddenFeeds.length === feeds.length - 1 &&
            !config.hiddenFeeds.includes(item.name)
          "
        />
      </template>
    </Fieldset>
    <div class="config__copyright">
      <p class="config__text">
        v. {{ env.VUE_APP_VERSION }}<br />
        © 2014–{{ env.VUE_APP_BUILD_YEAR }}
        <a href="https://fokke.fi">Ville Saarivaara</a>
      </p>
      <p class="config__text">
        <strong>Palvelu ei seuraa käyttäjää millään tavoin.</strong>
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Feed } from '@/types.d.ts'
import { ref, onMounted } from 'vue'
import { config, searchTargets } from '@/lib/config'
import { themes } from '@/lib/theme'
import { useApi } from '@/lib/api'

const env = process.env
const feeds = ref<Feed[]>([])
const feedsApi = useApi()

const loadFeeds = async () => {
  const response = await feedsApi.request('feeds/').catch(() => {
    feedsApi.error.value = 'Lähteiden lataaminen ei onnistunut :('
  })

  if (!response) return

  feeds.value = response
}

onMounted(() => {
  loadFeeds()
})
</script>

<style lang="scss" scoped>
.config {
  padding: var(--spacing-single) var(--spacing-section)
    calc(var(--spacing-section) * 2) var(--spacing-section);

  &__text {
    text-align: center;
    font-size: var(--font-size-small);
    line-height: 1.5;
    margin: 0 0 1em 0;

    &:last-child {
      margin-bottom: 0;
    }

    a {
      color: inherit;
      text-decoration-color: var(--color-primary-light);
      text-underline-offset: 0.4em;

      &:hover,
      &:focus {
        text-decoration: none;
        color: var(--color-primary-light);
      }
    }
  }
}
</style>
