import { ref } from 'vue'

export interface ApiOptions {
  /** Silent mode. If true, loading will not be toggled. */
  silent?: boolean

  /** Default value for loading state */
  loadingDefault?: boolean
}

export interface RequestOptions {
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE'
  mode?: 'cors' | 'no-cors' | 'same-origin'
  cache?:
    | 'default'
    | 'no-store'
    | 'reload'
    | 'no-cache'
    | 'force-cache'
    | 'only-if-cached'
  headers?: Record<string, string>
  body?: BodyInit
}

export const useApi = (userOptions: ApiOptions = {}) => {
  const apiOptions: ApiOptions = {
    silent: false,
    loadingDefault: false,
    ...userOptions,
  }

  const loading = ref<boolean>(apiOptions.loadingDefault === true)
  const error = ref<string | null>(null)

  return {
    loading,
    error,
    request: (url: string, options: RequestOptions = {}) => {
      error.value = null

      if (apiOptions.silent === false) {
        loading.value = true
      }

      options.method = options.method || 'GET'
      options.mode = 'cors'
      options.cache = 'no-cache'
      options.headers = {
        ...(options.headers || {}),
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }

      const params = new URLSearchParams()
      const paramsStr = params.size ? `?${params.toString()}` : ''

      const requestUrl = `${import.meta.env.VITE_API_ROOT}/${url}${paramsStr}`

      return fetch(requestUrl, options)
        .then((response) => {
          if (!response.ok) {
            throw new Error(`${response.status} ${response.statusText}`)
          }

          return response.json()
        })
        .finally(() => {
          loading.value = false
        })
    },
  }
}
